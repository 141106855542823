




import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class PeIcon extends Vue {
  @Prop() private icon!: string;
}
